import { createSlice } from "@reduxjs/toolkit";
import {
  submitOnboardingThunk,
  updateOnboardingThunk,
  fetchOnboardingThunk,
} from "../thunks/onboardingThunk";

const initialState = {
  data: null, // Store all onboarding data as an object
  isLoading: false,
  isCompleted: false,
  error: null,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    updateAnswers: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnboardingThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchOnboardingThunk.fulfilled, (state, action) => {
        state.data = action.payload.answers;
        state.isCompleted = true;
        state.isLoading = false;
      })
      .addCase(fetchOnboardingThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateOnboardingThunk.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload.answers };
      });
  },
});

export const { updateAnswers } = onboardingSlice.actions;
export default onboardingSlice.reducer;

// const initialState = {
//   answers: [],
//   isCompleted: false,
//   error: null,
// };

// const onboardingSlice = createSlice({
//   name: "onboarding",
//   initialState,
//   reducers: {
//     updateAnswers: (state, action) => {
//       state.answers = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(submitOnboardingThunk.pending, (state) => {
//         state.error = null;
//       })
//       .addCase(submitOnboardingThunk.fulfilled, (state, action) => {
//         state.answers = action.payload.answers || []; // Ensure answers exists
//         state.isCompleted = true;
//       })
//       .addCase(submitOnboardingThunk.rejected, (state, action) => {
//         state.error = action.payload;
//       })
//       .addCase(updateOnboardingThunk.fulfilled, (state, action) => {
//         state.answers = action.payload.answers || [];
//       })
//       .addCase(fetchOnboardingThunk.fulfilled, (state, action) => {
//         // console.log("Fetch onboarding payload:", action.payload); // Log the payload
//         state.answers = action.payload.answers || []; // Check and update answers
//         state.isCompleted = true; // Mark onboarding as completed if data exists
//       })
//       .addCase(fetchOnboardingThunk.rejected, (state, action) => {
//         state.error = action.payload;
//       });
//   },
// });

// export const { updateAnswers } = onboardingSlice.actions;
// export default onboardingSlice.reducer;
