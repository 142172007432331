import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import DiscoverMap from "../components/discoverPage/DiscoverMap";
import PropertiesGrid from "../components/discoverPage/PropertiesGrid";
import FilterBar from "../components/discoverPage/FilterBar";
import { MapIcon, QueueListIcon } from "@heroicons/react/24/outline";
import { fetchGeospatialListingsThunk } from "../store/thunks/repliersThunk";
import { useDispatch } from "react-redux";

export default function DiscoverPage() {
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const geospatialData = useSelector((state) => state.repliers.geospatialData);
  const [userLocation, setUserLocation] = useState(null);
  const [filters, setFilters] = useState({});
  const [isMapView, setIsMapView] = useState(true); // Default to Map View on mobile
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        () => {
          setUserLocation({ latitude: 43.6426, longitude: -79.3871 }); // Default to CN Tower
        }
      );
    } else {
      setUserLocation({ latitude: 43.6426, longitude: -79.3871 }); // Default to CN Tower
    }
  }, []);

  const handleFiltersChange = (updatedFilters) => {
    setFilters(updatedFilters);
  };

  return (
    <div className="relative h-screen w-full overflow-hidden">
      {/* Filters Section */}
      <div className="top-0  left-0 w-full z-20">
        <FilterBar onFiltersChange={handleFiltersChange} />
      </div>

      {/* Content Section */}
      <div className="relative h-full flex flex-col md:flex-row bg-gray-50 pt-[3rem]">
        {/* Mobile View */}
        {isMobile ? (
          <div className="absolute inset-0">
            {isMapView ? (
              // Mobile Map View
              <div className="absolute inset-0 h-[calc(100vh-3rem)]">
                <DiscoverMap
                  locations={geospatialData.listings || []}
                  userLocation={userLocation}
                  filters={filters}
                  ref={mapRef}
                />
              </div>
            ) : (
              // Mobile List View
              <div className="absolute inset-0 overflow-y-auto mt-2 bg-white">
                <PropertiesGrid
                  properties={geospatialData.listings || []}
                  fetchProperties={(pageNum, resultsPerPage) => {
                    const updatedFilters = { ...filters, pageNum, resultsPerPage };
                    setFilters(updatedFilters);
                    dispatch(
                      fetchGeospatialListingsThunk({
                        map: geospatialData.map,
                        filters: updatedFilters,
                      })
                    );
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          // Desktop View
          <div className="flex w-full h-[calc(100vh-3rem)]">
            {/* Properties Grid (Left Half) */}
            <div className="w-1/2 overflow-y-auto border-r border-gray-200">
              <PropertiesGrid
                properties={geospatialData.listings || []}
                fetchProperties={(pageNum, resultsPerPage) => {
                  const updatedFilters = { ...filters, pageNum, resultsPerPage };
                  setFilters(updatedFilters);
                  dispatch(
                    fetchGeospatialListingsThunk({
                      map: geospatialData.map,
                      filters: updatedFilters,
                    })
                  );
                }}
              />
            </div>

            {/* Map (Right Half) */}
            <div className="w-1/2 relative">
              <DiscoverMap
                locations={geospatialData.listings || []}
                userLocation={userLocation}
                filters={filters}
                ref={mapRef}
              />
            </div>
          </div>
        )}
      </div>

      {/* Mobile View Toggle Button */}
      {isMobile && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-30">
          <button
            className={`flex items-center px-4 py-2 shadow-md ${
              isMapView ? "bg-[#0A2342] text-white" : "bg-gray-100 text-[#0A2342]"
            }`}
            onClick={() => setIsMapView((prev) => !prev)}>
            {isMapView ? (
              <>
                <QueueListIcon className="w-5 h-5 mr-2" />
                List View
              </>
            ) : (
              <>
                <MapIcon className="w-5 h-5 mr-2" />
                Map View
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
}
