import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFavoritesListsThunk,
  createFavoritesListThunk,
  addPropertyToListThunk,
} from "../../store/thunks/favoritesThunk";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

const AddToFavoritesModal = ({ property, onClose }) => {
  const dispatch = useDispatch();
  const [selectedList, setSelectedList] = useState("");
  const [newListName, setNewListName] = useState("");
  const [newListDescription, setNewListDescription] = useState("");
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [nickname, setNickname] = useState("");

  const lists = useSelector((state) => state.favorites.lists);

  useEffect(() => {
    dispatch(fetchFavoritesListsThunk());
  }, [dispatch]);

  const handleAddToList = async () => {
    const propertyData = {
      mlsNumber: property.mlsNumber,
      boardId: property.boardId,
      address: property.address,
      nickname: nickname || null,
    };

    try {
      if (isCreatingNew) {
        await dispatch(
          createFavoritesListThunk({
            name: newListName,
            description: newListDescription,
            properties: [propertyData],
          })
        ).unwrap();
      } else {
        await dispatch(
          addPropertyToListThunk({
            listId: selectedList,
            property: propertyData,
          })
        ).unwrap();
      }
      toast.success(
        `${property.address.streetNumber} ${property.address.streetName} ${property.address.streetSuffix} added successfully.`
      );
      onClose();
    } catch (error) {
      console.error("Error adding property to list:", error);
      toast.error("Error adding property to list.", {});
    }
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 pointer-events-auto"
      onClick={onClose}>
      <div className="bg-white p-6 max-w-md w-full" onClick={handleModalClick}>
        <h2 className="text-2xl font-bold text-[#0A2342] mb-4">
          Add to Favorites
        </h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Property Nickname (Optional)
          </label>
          <input
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            className="w-full p-2 border"
            placeholder="e.g., Dream House"
          />
        </div>
        {isCreatingNew ? (
          <>
            <div className="mb-4">
              <input
                type="text"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
                className="w-full p-2 border"
                placeholder="New list name"
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                value={newListDescription}
                onChange={(e) => setNewListDescription(e.target.value)}
                className="w-full p-2 border"
                placeholder="New list description"
              />
            </div>
            <button
              onClick={() => setIsCreatingNew(false)}
              className="text-[#0A2342] text-sm mb-4 block">
              Use Existing List
            </button>
          </>
        ) : (
          <>
            <div className="relative mb-4">
              <select
                value={selectedList}
                onChange={(e) => setSelectedList(e.target.value)}
                className="block w-full appearance-none bg-white border border-gray-300 py-2 px-3 pr-10 text-sm leading-5 text-gray-900 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                <option value="">Select a list</option>
                {lists.map((list) => (
                  <option key={list._id} value={list._id}>
                    {list.name}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none text-[#0A2342] absolute inset-y-0 right-0 flex items-center pr-3">
                <ChevronDownIcon h-5 w-5 />
              </div>
            </div>
            <button
              onClick={() => setIsCreatingNew(true)}
              className="text-[#0A2342] text-sm mb-4 block">
              Create New List
            </button>
          </>
        )}
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-[#0A2342] text-[#0A2342]">
            Cancel
          </button>
          <button
            onClick={handleAddToList}
            className="px-4 py-2 bg-[#0A2342] text-white">
            Add to List
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddToFavoritesModal;
