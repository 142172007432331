import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removePropertyFromListThunk } from "../../store/thunks/favoritesThunk";
import { toast } from "react-toastify";

const RemoveFromFavoritesModal = ({ property, onClose }) => {
  const dispatch = useDispatch();
  const favoritesLists = useSelector((state) => state.favorites.lists);

  // Find the list containing the property
  const list = favoritesLists.find((list) =>
    list.properties.some((prop) => prop.mlsNumber === property.mlsNumber)
  );

  const handleRemove = async () => {
    if (!list) {
      toast.error("Could not find the list containing this property.");
      return;
    }

    try {
      await dispatch(
        removePropertyFromListThunk({
          listId: list._id,
          propertyId: property.mlsNumber,
        })
      ).unwrap();
      toast.success(
        `${property.address.streetNumber} ${property.address.streetName} ${property.address.streetSuffix} removed from favorites.`
      );
      onClose();
    } catch (error) {
      toast.error("Failed to remove property from favorites.");
      console.error(error);
    }
  };

  return (
    <div
      className="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 pointer-events-auto"
      onClick={onClose}>
      <div
        className="bg-white p-6 max-w-md w-full"
        onClick={(e) => e.stopPropagation()}>
        <h2 className="text-2xl font-bold text-[#0A2342] mb-4">
          Remove from Favorites
        </h2>
        <p>
          Remove {property.address.streetNumber} {property.address.streetName}{" "}
          {property.address.streetSuffix} from your favorites?
        </p>

        <div className="flex justify-end space-x-2 mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-[#0A2342] text-[#0A2342]">
            Cancel
          </button>
          <button
            onClick={handleRemove}
            className="px-4 py-2 bg-[#0A2342] text-white">
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveFromFavoritesModal;
