import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BellIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
  ListBulletIcon,
  AdjustmentsHorizontalIcon
} from "@heroicons/react/24/outline";
// import Preferences from "./Preferences";
import General from "../components/settingsComponents/General";
import Preferences from "../components/settingsComponents/Preferences";

const secondaryNavigation = [
  { name: "General", href: "#", icon: UserCircleIcon, current: true },
  { name: "Preferences", href: "#", icon: AdjustmentsHorizontalIcon, current: false },
  { name: "Security", href: "#", icon: FingerPrintIcon, current: false },
  { name: "Notifications", href: "#", icon: BellIcon, current: false },
  { name: "Plan", href: "#", icon: CubeIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SettingsPage() {
  const [currentSection, setCurrentSection] = useState("General");

  const renderSection = () => {
    switch (currentSection) {
      case "General":
        // return (
        //   <div>
        //     <h2 className="text-base font-semibold leading-7 text-gray-900">
        //       Profile
        //     </h2>
        //     <p className="mt-1 text-sm leading-6 text-gray-500">
        //       This information was automatically gathered from your
        //       registration.
        //     </p>
        //     <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        //       <div className="pt-6 sm:flex">
        //         <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
        //           First Name
        //         </dt>
        //         <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
        //           <div className="text-gray-900">{user.firstName}</div>
        //           <button
        //             type="button"
        //             className="font-semibold text-[#0A2342] hover:text-gold-500">
        //             Update
        //           </button>
        //         </dd>
        //       </div>
        //       <div className="pt-6 sm:flex">
        //         <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
        //           Last Name
        //         </dt>
        //         <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
        //           <div className="text-gray-900">{user.lastName}</div>
        //           <button
        //             type="button"
        //             className="font-semibold text-[#0A2342] hover:text-gold-500">
        //             Update
        //           </button>
        //         </dd>
        //       </div>
        //       <div className="pt-6 sm:flex">
        //         <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
        //           Username
        //         </dt>
        //         <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
        //           <div className="text-gray-900">{user.username}</div>
        //           <button
        //             type="button"
        //             className="font-semibold text-[#0A2342] hover:text-gold-500">
        //             Update
        //           </button>
        //         </dd>
        //       </div>
        //     </dl>{" "}
        //   </div>
        // );
        return <General />;
      case "Preferences":
        return <Preferences />;
      case "Security":
        return (
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Security Settings
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Manage your security settings here.
            </p>
            {/* Add content for the Security section */}
          </div>
        );
      case "Notifications":
        return (
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Notification Settings
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Manage your notification preferences here.
            </p>
            {/* Add content for the Notifications section */}
          </div>
        );
      case "Plan":
        return (
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Plan Details
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              View and manage your plan details here.
            </p>
            {/* Add content for the Plan section */}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mx-auto max-w-7xl pt-8 lg:flex lg:gap-x-16 lg:px-8">
      <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-10">
        <nav className="flex-none px-4 sm:px-6 lg:px-0">
          <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
            {secondaryNavigation.map((item) => (
              <li key={item.name}>
                <a
                  href={item.href}
                  onClick={() => setCurrentSection(item.name)}
                  className={classNames(
                    currentSection === item.name
                      ? "border border-[#0A2342] text-gold-600"
                      : "text-gray-700 hover:border hover:border-[#0A2342] hover:text-gold-600",
                    "group flex gap-x-3 py-2 pl-2 pr-3 text-sm font-semibold leading-6"
                  )}>
                  <item.icon
                    aria-hidden="true"
                    className={classNames(
                      currentSection === item.name
                        ? "text-gold-600"
                        : "text-gray-400 group-hover:text-gold-600",
                      "h-6 w-6 shrink-0"
                    )}
                  />
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </aside>

      <main className="px-4 py-8 sm:px-6 lg:flex-auto lg:px-0 lg:py-10">
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          {renderSection()}
        </div>
      </main>
    </div>
  );
}
