import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

export const fetchGeneralPropertiesThunk = createAsyncThunk(
  "data/fetchProperties",
  async ({ pageNum = 1 }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/properties", {
        headers: config.headers,
        params: {
          pageNum,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching property data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch property data"
      );
    }
  }
);

export const fetchAggregatesThunk = createAsyncThunk(
  "data/fetchAggregates",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/aggregates", {
        ...config,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching property data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch property data"
      );
    }
  }
);

export const fetchPersonalizedPropertiesThunk = createAsyncThunk(
  "data/fetchPersonalizedProperties",
  async ({ pageNum = 1 }, { getState, rejectWithValue }) => {
    try {
      const { token, user } = getState().auth; // Assuming user info is in the auth state
      const config = generateConfig(token);

      const response = await api.get(`/v1/api/personalized-properties`, {
        headers: {
          ...config.headers,
        },
        params: {
          userId: user._id, // Send only the userId
          pageNum, // Pass the current page number
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch personalized properties"
      );
    }
  }
);

export const fetchPersonalizedSoldThunk = createAsyncThunk(
  "data/fetchPersonalizedSold",
  async ({ pageNum = 1 }, { getState, rejectWithValue }) => {
    try {
      const { token, user } = getState().auth; // Assuming user info is in the auth state
      const config = generateConfig(token);

      const response = await api.get(`/v1/api/personalized-sold`, {
        headers: {
          ...config.headers,
        },
        params: {
          userId: user._id, // Send only the userId
          pageNum, // Pass the current page number
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch personalized properties (sold, 60 days)"
      );
    }
  }
);

export const fetchPersonalizedListThunk = createAsyncThunk(
  "data/fetchPersonalizedSold",
  async ({ pageNum = 1 }, { getState, rejectWithValue }) => {
    try {
      const { token, user } = getState().auth; // Assuming user info is in the auth state
      const config = generateConfig(token);

      const response = await api.get(`/v1/api/personalized-list`, {
        headers: {
          ...config.headers,
        },
        params: {
          userId: user._id,
          pageNum,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch personalized properties (listed, 60 days)"
      );
    }
  }
);

export const fullTextSearchThunk = createAsyncThunk(
  "properties/fullTextSearch",
  async ({ search }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(`/v1/api/full-text-search`, {
        ...config,
        params: { search },
      });

      console.log("API response data:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error searching with full text search:", error);
      return rejectWithValue("Failed to search with full text search");
    }
  }
);

export const fetchSingleListingThunk = createAsyncThunk(
  "data/fetchSingleListing",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);
      const response = await api.get(
        `/v1/api/properties/${mlsNumber}?boardId=${boardId}`,
        config
      );
      console.log("Fetched single listing data:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching single listing data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch single listing data"
      );
    }
  }
);

export const fetchSoldListingThunk = createAsyncThunk(
  "data/fetchSoldListing",
  async ({ mlsNumber }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);
      const response = await api.post(
        `/v1/api/properties/sold/${mlsNumber}`,
        config
      );
      console.log("Fetched single sold listing data:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching single sold listing data:", error);
      return rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch single sold listing data"
      );
    }
  }
);

export const fetchNlpPropertiesThunk = createAsyncThunk(
  "data/fetchNlpProperties",
  async (prompt, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      // Make a POST request to your backend route
      const response = await api.post("/v1/api/nlp-search", { prompt }, config);

      console.log("Response from backend NLP search:", response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching NLP properties:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch NLP properties"
      );
    }
  }
);

export const fetchMarketStatisticsThunk = createAsyncThunk(
  "data/fetchMarketStatistics",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(
        `/v1/api/market-statistics?mlsNumber=${mlsNumber}&boardId=${boardId}`,
        config
      );

      console.log("Fetched market statistics:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching market statistics:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch market statistics"
      );
    }
  }
);

export const fetchPropertyHistoryThunk = createAsyncThunk(
  "data/fetchPropertyHistory",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(
        `/v1/api/properties/history?mlsNumber=${mlsNumber}&boardId=${boardId}`,
        config
      );

      console.log("Fetched property history:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching property history:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch property history"
      );
    }
  }
);

export const fetchSimilarPropertiesThunk = createAsyncThunk(
  "data/fetchSimilarProperties",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      // Log the parameters being sent
      console.log(
        "Fetching similar properties with mlsNumber:",
        mlsNumber,
        "and boardId:",
        boardId
      );

      const response = await api.get(
        `/v1/api/properties/similar/${mlsNumber}?boardId=${boardId}`,
        config
      );

      console.log("Fetched similar properties successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching similar property data:",
        error.response?.data || error.message
      );
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch similar property data"
      );
    }
  }
);

export const fetchImageThunk = createAsyncThunk(
  "data/fetchImage",
  async (imageName, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(
        `/v1/api/properties/image?name=${imageName}`,
        config
      );
      return response.data.image;
    } catch (error) {
      console.error("Error fetching image:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch image"
      );
    }
  }
);

export const fetchGeospatialListingsThunk = createAsyncThunk(
  "data/fetchGeospatialListings",
  async ({ map, filters }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      console.log("Making geospatial request with map and filters:", {
        map,
        filters,
      });

      // Include filters in the request body
      const response = await api.post(
        `/v1/api/properties/geospatial`,
        { map, filters }, // Add filters to the body
        config
      );

      console.log("Fetched polygon listings data:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching polygon listings data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch polygon listings"
      );
    }
  }
);

export const fetchClusterListingsThunk = createAsyncThunk(
  "data/fetchClusterListings",
  async ({ map, filters }, { getState, rejectWithValue }) => {
    try {
      console.log("Fetching cluster listings");

      const { token } = getState().auth;
      const config = generateConfig(token);

      console.log("Making cluster request with map and filters:", {
        map,
        filters,
      });

      // Include filters in the request body
      const response = await api.post(
        `/v1/api/properties/cluster`,
        { map, filters }, // Add filters to the body
        config
      );

      console.log("Fetched polygon listings data:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching polygon listings data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch polygon listings"
      );
    }
  }
);

export const fetchLocalDataThunk = createAsyncThunk(
  "data/fetchLocalData",
  async ({ lat, long }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);
      const response = await api.get(
        `/v1/api/local-places?lat=${lat}&long=${long}`,
        config
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching local data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch local data"
      );
    }
  }
);

export const saveCollectionsThunk = createAsyncThunk(
  "data/saveCollections",
  async (collections, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);
      const response = await api.post(
        "/api/user/collections",
        { collections },
        config
      );
      return response.data; // Assuming the response contains the updated collections
    } catch (error) {
      console.error("Error saving collections:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to save collections"
      );
    }
  }
);
