import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  fetchGeospatialListingsThunk,
  fetchClusterListingsThunk,
} from "../../store/thunks/repliersThunk";
import PropertyRectangularCard from "../properties/PropertyRectangularCard";
import * as maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "./DiscoverMap.css";

const MAPTILER_API_KEY = process.env.REACT_APP_MAPTILER_API_KEY;
const DEFAULT_LOCATION = { longitude: -79.3871, latitude: 43.6426 }; // CN Tower, Toronto

const DiscoverMap = forwardRef(
  ({ locations, userLocation = DEFAULT_LOCATION, filters }, ref) => {
    const mapContainer = useRef(null);
    const mapInstance = useRef(null);
    const popupRef = useRef(null);
    const dispatch = useDispatch();

    const [selectedProperty, setSelectedProperty] = useState(null);
    const [mapStyle, setMapStyle] = useState("topo");
    const markersRef = useRef([]); // Store marker references for cleanup

    console.log("received filters in DiscoverMap", filters);

    useImperativeHandle(ref, () => ({
      flyTo: (options) => {
        if (mapInstance.current) {
          mapInstance.current.flyTo(options);
        }
      },
    }));

    useEffect(() => {
      if (!mapInstance.current) {
        mapInstance.current = mapInitializer();
      }

      const handleMoveEnd = async () => {
        const bounds = mapInstance.current.getBounds();
        const northeast = bounds.getNorthEast();
        const southwest = bounds.getSouthWest();

        const corners = [
          [southwest.lng, northeast.lat],
          [northeast.lng, northeast.lat],
          [northeast.lng, southwest.lat],
          [southwest.lng, southwest.lat],
          [southwest.lng, northeast.lat],
        ];

        const geospatialData = {
          map: [corners],
          filters, // Pass the latest filters
        };

        try {
          console.log("Fetching listings due to map movement with filters:", {
            geospatialData,
          });
          await dispatch(fetchGeospatialListingsThunk(geospatialData)).unwrap();
          await dispatch(fetchClusterListingsThunk());
        } catch (error) {
          console.error("Error fetching listings after map movement:", error);
        }
      };

      // Attach the `moveend` event listener
      mapInstance.current.on("moveend", handleMoveEnd);

      return () => {
        // Cleanup on unmount
        mapInstance.current.off("moveend", handleMoveEnd);
      };
    }, [filters]); // Add `filters` as a dependency

    const mapInitializer = () => {
      console.log("Initializing map...");
      const map = new maplibregl.Map({
        container: mapContainer.current,
        style: `https://api.maptiler.com/maps/${mapStyle}/style.json?key=${MAPTILER_API_KEY}`,
        center: [
          userLocation?.longitude || DEFAULT_LOCATION.longitude,
          userLocation?.latitude || DEFAULT_LOCATION.latitude,
        ],
        minZoom: 3,
        maxZoom: 18,
        // pitch: 50,
        zoom: 13,
      });

      mapInstance.current = map;
      map.addControl(new maplibregl.NavigationControl(), "top-right");

      map.on("load", () => {
        map.addSource("properties", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: locations.map((location) => ({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [
                  parseFloat(location.map.longitude),
                  parseFloat(location.map.latitude),
                ],
              },
              properties: location,
            })),
          },
        });

        map.on("click", "unclustered-point", (e) => {
          const { properties } = e.features[0];
          setSelectedProperty(properties);
        });

        map.on("mouseenter", "unclustered-point", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        map.on("mouseleave", "unclustered-point", () => {
          map.getCanvas().style.cursor = "";
        });
      });

      return map;
    };

    useEffect(() => {
      if (!mapInstance.current && userLocation) {
        mapInstance.current = mapInitializer();

        const fetchInitialListings = async () => {
          const bounds = mapInstance.current.getBounds();

          const northeast = bounds.getNorthEast();
          const southwest = bounds.getSouthWest();

          const corners = [
            [southwest.lng, northeast.lat],
            [northeast.lng, northeast.lat],
            [northeast.lng, southwest.lat],
            [southwest.lng, southwest.lat],
            [southwest.lng, northeast.lat],
          ];

          const geospatialData = {
            map: [corners],
            filters, // Add filters to payload
          };

          try {
            console.log(
              "Fetching initial polygon listings with filters...",
              geospatialData
            );
            await dispatch(
              fetchGeospatialListingsThunk(geospatialData),
              fetchClusterListingsThunk()
            ).unwrap();
          } catch (error) {
            console.error(
              "Error fetching initial polygon listings with filters:",
              error
            );
          }
        };

        fetchInitialListings();
      } else if (mapInstance.current) {
        mapInstance.current.setStyle(
          `https://api.maptiler.com/maps/${mapStyle}/style.json?key=${MAPTILER_API_KEY}`
        );
      }
    }, [userLocation, filters, mapStyle]);

    useEffect(() => {
      if (mapInstance.current) {
        clearMarkers();
        renderPins();
      }
    }, [locations]);

    useEffect(() => {
      if (mapInstance.current) {
        const bounds = mapInstance.current.getBounds();
        const northeast = bounds.getNorthEast();
        const southwest = bounds.getSouthWest();

        const corners = [
          [southwest.lng, northeast.lat],
          [northeast.lng, northeast.lat],
          [northeast.lng, southwest.lat],
          [southwest.lng, southwest.lat],
          [southwest.lng, northeast.lat],
        ];

        const geospatialData = {
          map: [corners],
          filters,
        };

        try {
          console.log("Fetching listings due to filter change...", {
            geospatialData,
          });
          dispatch(fetchGeospatialListingsThunk(geospatialData));
          dispatch(fetchClusterListingsThunk());
        } catch (error) {
          console.error("Error fetching listings after filter change:", error);
        }
      }
    }, [filters, dispatch, mapStyle]);

    const clearMarkers = () => {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
    };

    const renderPins = () => {
      if (!mapInstance.current || !locations) return;

      clearMarkers();

      locations.forEach((property) => {
        const { latitude, longitude } = property.map || {};
        if (!latitude || !longitude) return;

        // Create tooltip element
        const tooltipEl = document.createElement("div");
        tooltipEl.className = "marker-tooltip";
        tooltipEl.style.display = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.backgroundColor = "white";
        tooltipEl.style.padding = "8px 12px";
        tooltipEl.style.borderRadius = "4px";
        tooltipEl.style.boxShadow = "0 2px 4px rgba(0,0,0,0.1)";
        tooltipEl.style.fontSize = "14px";
        tooltipEl.style.zIndex = "20";
        tooltipEl.style.minWidth = "150px";
        tooltipEl.style.color = "#0A2342";
        // tooltipEl.style.transform = "translateY(-100%)";
        // tooltipEl.style.marginTop = "20px";
        // Construct address string
        let address = [
          property.address.streetNumber,
          property.address.streetName,
          property.address.streetSuffix,
        ]
          .filter(Boolean)
          .join(" ");

        if (property.address.unitNumber) {
          address = `${property.address.unitNumber} - ${address}`;
        }

        tooltipEl.textContent = address;

        // Create marker element (using default pin)
        const marker = new maplibregl.Marker({
          color: "#0A2342",
          opacityWhenCovered: "50%",
          // opacity: '50%',
          // draggable: true,
          // color: "#E5B13A",
        })
          .setLngLat([parseFloat(longitude), parseFloat(latitude)])
          .addTo(mapInstance.current);

        // Get the marker element and add tooltip
        const markerEl = marker.getElement();
        markerEl.appendChild(tooltipEl);

        markersRef.current.push(marker);

        // Event listeners for marker interactions
        markerEl.addEventListener("click", () => {
          setSelectedProperty(property);
        });

        markerEl.addEventListener("mouseenter", () => {
          tooltipEl.style.display = "block";
        });

        markerEl.addEventListener("mouseleave", () => {
          tooltipEl.style.display = "none";
        });
      });
    };

    const changeMapStyle = (newStyle) => {
      setMapStyle(newStyle);
    };

    return (
      <div
        ref={mapContainer}
        className="w-full h-full absolute inset-0"
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}>
        <div className="absolute top-2 left-2 bg-white p-1 shadow-md flex flex-wrap space-x-1 max-w-full">
          {["topo", "streets", "basic", "bright", "pastel", "hybrid"].map(
            (style) => (
              <button
                key={style}
                onClick={() => changeMapStyle(style)}
                className={`text-xs px-1 py-0.5 ${
                  mapStyle === style
                    ? "bg-[#0A2342] text-white"
                    : "bg-white text-[#0A2342] border border-[#0A2342]"
                }`}>
                {style.charAt(0).toUpperCase() + style.slice(1)}
              </button>
            )
          )}
        </div>
        {selectedProperty && (
          <div
            className="z-40 absolute bottom-4 mb-10 left-1/2 transform -translate-x-1/2 z-50"
            style={{ width: "90%", maxWidth: "600px" }}>
            <PropertyRectangularCard property={selectedProperty} />
          </div>
        )}
      </div>
    );
  }
);

export default DiscoverMap;
