import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOnboardingThunk,
  updateOnboardingThunk,
} from "../../store/thunks/onboardingThunk";
import OnboardingQuestions from "../onboardingQuestions";
import LoadingSpinner from "../LoadingSpinner";

const Preferences = () => {
  const dispatch = useDispatch();
  const {
    data: onboardingData,
    isLoading,
    error,
  } = useSelector((state) => state.onboarding);

  const [editedData, setEditedData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(fetchOnboardingThunk());
  }, [dispatch]);

  useEffect(() => {
    if (onboardingData) setEditedData(onboardingData);
  }, [onboardingData]);

  const handleInputChange = (key, value) => {
    setEditedData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSave = async () => {
    try {
      await dispatch(updateOnboardingThunk(editedData)).unwrap();
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to save onboarding data:", error);
    }
  };

  const renderField = (question) => {
    const value = editedData?.[question.key] || "";

    switch (question.type) {
      case "radio":
        return (
          <div className="space-y-2">
            {question.options.map((option) => (
              <label key={option} className="flex items-center space-x-2">
                <input
                  type="radio"
                  name={question.key}
                  value={option}
                  checked={value === option}
                  onChange={(e) =>
                    handleInputChange(question.key, e.target.value)
                  }
                  className="form-radio text-[#0A2342]"
                />
                <span>{option}</span>
              </label>
            ))}
          </div>
        );
      case "multi-select":
        return (
          <div className="space-y-2">
            {question.options.map((option) => (
              <label key={option} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={option}
                  checked={(value || []).includes(option)}
                  onChange={(e) => {
                    const updatedValue = e.target.checked
                      ? [...(value || []), option]
                      : (value || []).filter((v) => v !== option);
                    handleInputChange(question.key, updatedValue);
                  }}
                  className="form-checkbox text-[#0A2342]"
                />
                <span>{option}</span>
              </label>
            ))}
          </div>
        );
      default:
        return (
          <input
            type="text"
            value={value}
            onChange={(e) => handleInputChange(question.key, e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        );
    }
  };

  if (isLoading) return <LoadingSpinner title="Loading preferences..." />;
  if (error) return <p className="text-red-600">Error: {error}</p>;

  return (
    <div className="bg-white p-6 shadow-md rounded-md">
      <h2 className="text-xl font-bold mb-4">Preferences</h2>
      <form className="space-y-4">
        {OnboardingQuestions.map((question) => (
          <div key={question.key} className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {question.name}
            </label>
            {renderField(question)}
          </div>
        ))}
      </form>
      <div className="mt-6 flex space-x-4">
        {isEditing ? (
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-green-500 text-white rounded">
            Save
          </button>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="px-4 py-2 bg-blue-500 text-white rounded">
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default Preferences;
