// thunks/emailThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

export const sendContactEmailThunk = createAsyncThunk(
  "email/sendContact",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      // Make sure this matches exactly how other API calls are structured in your app
      const response = await api.post(
        "/v1/api/email/send-contact",  // Remove leading slash if your other API calls don't use it
        formData,
        config
      );
      
      return response.data;
    } catch (error) {
      // Add more detailed error logging
      console.error("Email sending error:", error.response || error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to send email"
      );
    }
  }
);