import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0];
    return (
      <div className="bg-gray-100 border border-[#0A2342] p-3 shadow-md w-[200px]">
        <p className="text-gray-700 font-bold">{data.payload.tooltipLabel}</p>
        <p className="text-sm text-gray-800">
          Value:{" "}
          <span className="font-bold">${data.value.toLocaleString()}</span>
        </p>
      </div>
    );
  }
  return null;
};

const EstimatedValue = ({ property, marketStatistics }) => {
  if (
    !property ||
    !marketStatistics ||
    (!marketStatistics.statistics?.soldPrice &&
      !marketStatistics.statistics?.listPrice)
  ) {
    return <p>No data available.</p>;
  }

  const isSold = property?.soldPrice && property?.soldDate;
  const propertyPrice = Math.round(
    parseFloat(isSold ? property.soldPrice : property.listPrice)
  );

  const averagePrice = Math.round(
    isSold
      ? marketStatistics.statistics.soldPrice.avg
      : marketStatistics.statistics.listPrice.avg
  );
  const upperBound = Math.round(averagePrice * 1.1);
  const lowerBound = Math.round(averagePrice * 0.9);

  const chartData = [
    {
      label: "Lower",
      tooltipLabel: "Lower Bounds",
      value: lowerBound,
      percentage: lowerBound,
    },
    {
      label: "Average",
      tooltipLabel: isSold ? "Average Sold Price" : "Average List Price",
      value: averagePrice,
      percentage: averagePrice,
    },
    {
      label: "Upper",
      tooltipLabel: "Upper Bounds",
      value: upperBound,
      percentage: upperBound,
    },
  ];

  const getComparison = () => {
    const diff = propertyPrice - averagePrice;
    const percentage = Math.abs((diff / averagePrice) * 100).toFixed(1);

    // Log the values for debugging
    console.log("Property Price:", propertyPrice);
    console.log("Average Price:", averagePrice);
    console.log("Difference:", diff);
    console.log("Percentage Difference:", percentage);
    console.log("Direction:", diff > 0 ? "above" : "below");

    return {
      diff,
      percentage,
      direction: diff > 0 ? "above" : "below",
    };
  };

  const comparison = getComparison();

  return (
    <div className="flex flex-col md:flex-row md:items-start p-4 shadow-md gap-4">
      {/* Estimated Value */}
      <div className="w-full md:w-2/5">
        <h2 className="text-xl font-semibold text-[#0A2342] mb-4">
          Estimated Value
        </h2>
        <div>
          <p className="text-lg font-bold text-[#0A2342]">
            {isSold ? "Sold Price" : "List Price"} $
            {propertyPrice.toLocaleString()}
          </p>
          <p className="text-sm text-gray-600 mt-1">
            {isSold ? "Sold" : "Listed"}
            <span
              className={`text-sm font-semibold ${
                (isSold && comparison.direction === "above") ||
                (!isSold && comparison.direction === "below")
                  ? "text-green-500"
                  : "text-red-500"
              }`}>
              {" "}
              {comparison.percentage}% {comparison.direction}{" "}
            </span>
            the average of ${averagePrice.toLocaleString()} for similar
            properties.
          </p>
        </div>
      </div>

      {/* Bar Chart */}
      <div className="w-full md:w-3/5">
        <ResponsiveContainer width="100%" height={150}>
          <BarChart
            layout="vertical"
            data={chartData}
            margin={{ left: 20 }}
            barCategoryGap={20}>
            <XAxis type="number" hide />
            <YAxis
              type="category"
              dataKey="label"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
            />
            <Tooltip content={<CustomTooltip />} cursor={false} />
            <Bar
              dataKey="percentage"
              fill="#0A2342"
              barSize={40}
              label={{
                position: "insideLeft",
                formatter: (value) => `$${value.toLocaleString()}`,
                fill: "#fff",
                fontSize: 12,
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default EstimatedValue;
