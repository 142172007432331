import React, { useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

const ImageCarousel = ({ images = [], isGridTile = false }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="relative">
      <div className="flex justify-center">
        <div className={`w-full ${isGridTile ? "max-w-md" : "w-full"}`}>
          <img
            src={`data:image/jpeg;base64,${images[currentIndex]}`}
            alt={`Property`}
            className={`w-full h-full object-cover`}
            style={{
              aspectRatio: "4 / 3",
            }}
          />
        </div>
      </div>

      {images.length > 1 && (
        <>
          <button
            onClick={prevImage}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white/20 text-[#0A2342] p-2 focus:outline-none hover:bg-white/40 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed">
            <ArrowLeftIcon className="w-6 h-6" />
          </button>
          <button
            onClick={nextImage}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white/20 text-[#0A2342] p-2 focus:outline-none hover:bg-white/40 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed">
            <ArrowRightIcon className="w-6 h-6" />
          </button>
        </>
      )}
    </div>
  );
};

export default ImageCarousel;
