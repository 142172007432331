import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { fetchImageThunk } from "../../store/thunks/repliersThunk";
import ImageCarousel from "./ImageCarousel";
import { HeartIcon } from "@heroicons/react/24/outline";
import noImage from "../../assets/noimage.png";
import { useSelector } from "react-redux";
import AddToFavoritesModal from "../favorites/AddToFavoritesModal";
import RemoveFromFavoritesModal from "../favorites/RemoveFromFavoritesModal";

const PropertyGridTile = ({ property, boardId = null }) => {
  const dispatch = useDispatch();
  const [fetchedImages, setFetchedImages] = useState([]);
  const [showFavoritesModal, setShowFavoritesModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const favoritesLists = useSelector((state) => state.favorites.lists);
  const [isInFavorites, setIsInFavorites] = useState(false);

  const isSold = property?.soldPrice && property?.soldDate;

  // Fetch images
  useEffect(() => {
    if (property && property.images) {
      const fetchFirstImage = async () => {
        try {
          const firstImage = await dispatch(
            fetchImageThunk(property.images[0])
          ).unwrap();
          setFetchedImages([firstImage]);
        } catch (error) {
          console.error("Failed to fetch first image:", error);
        }
      };
      fetchFirstImage();
    }
  }, [property, dispatch]);

  // Check if in favourites
  useEffect(() => {
    const checkIsInFavorites = () => {
      const isFavorite = favoritesLists.some((list) =>
        list.properties
          .filter((prop) => prop)
          .some((prop) => prop.mlsNumber === property.mlsNumber)
      );
      setIsInFavorites(isFavorite);
    };

    if (property) {
      checkIsInFavorites();
    }
  }, [favoritesLists, property]);

  const createSlug = (address) => {
    if (!address) return "";
    const { streetNumber, streetName, city, neighborhood } = address;
    return [streetNumber, streetName, city, neighborhood]
      .filter(Boolean)
      .join("-")
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "");
  };

  const handleClick = (event) => {
    const slug = createSlug(property.address);
    const url = `/propertydetails/${property.mlsNumber}/${
      property.boardId || boardId
    }/${slug}`;
    if (event.metaKey || event.ctrlKey) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  const handleHeartClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isInFavorites) {
      setShowRemoveModal(true);
    } else {
      setShowFavoritesModal(true);
    }
  };

  const renderPermissionOverlay = () => {
    if (property.permissions.displayInternetEntireListing === "N") {
      return (
        <motion.div
          className="absolute inset-0 bg-red-500 bg-opacity-90 backdrop-blur-sm z-20 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}>
          <p className="text-white font-bold text-lg">
            Not available for display. displayInternetEntireListing is N
          </p>
        </motion.div>
      );
    } else if (property.permissions.displayAddressOnInternet === "N") {
      return (
        <motion.div
          className="absolute inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm z-20 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}>
          <p className="text-white font-bold text-lg">displayAddressOnInternet is N</p>
        </motion.div>
      );
    } else if (property.permissions.displayPublic === "N") {
      return (
        <motion.div
          className="absolute inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm z-20 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}>
          <p className="text-white font-bold text-lg">Login required</p>
        </motion.div>
      );
    }
    return null;
  };

  if (!property) {
    return (
      <div>
        <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
          Gathering property info...
        </h2>
      </div>
    );
  }

  return (
    <>
      <motion.div
        className={`overflow-hidden cursor-pointer shadow-md border border-[#0A2342] md:w-full w-10/12 mx-auto relative`}
        whileHover={{ scale: 1.01 }}
        transition={{ duration: 0.2 }}
        onClick={handleClick}>
        {/* {renderPermissionOverlay()} */}
        {/* Property Image and Tags */}
        <div className="relative">
          {isSold ? (
            <div className="z-10 absolute top-2 left-2 bg-[#0A2342] text-white text-xs font-semibold px-2 py-1">
              {(() => {
                const daysAgo = Math.floor(
                  (new Date() - new Date(property.soldDate)) /
                    (1000 * 60 * 60 * 24)
                );
                return daysAgo >= 0
                  ? `${daysAgo} days ago`
                  : new Date(property.soldDate).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    });
              })()}
            </div>
          ) : (
            <motion.div
              className="z-10 absolute top-2 left-2 cursor-pointer"
              whileHover={{ scale: 1.2 }}>
              <button
                onClick={handleHeartClick}
                className="absolute z-10 bg-opacity-80 
                   transition-all duration-200 hover:scale-110">
                {isInFavorites ? (
                  <HeartIcon className="h-6 w-6 text-[#fff] fill-[#0A2342]" />
                ) : (
                  <HeartIcon className="h-6 w-6 text-[#0A2342] fill-[#fff]" />
                )}
              </button>
            </motion.div>
          )}

          <div className="z-10 absolute top-2 right-2 cursor-pointer bg-[#0A2342] text-white text-xs font-semibold px-2 py-1">
            {isSold
              ? `${
                  property.distance !== null && property.distance !== undefined
                    ? `${property.distance.toFixed(2)} km away`
                    : `DOM: ${
                        property.daysOnMarket !== null &&
                        property.daysOnMarket !== undefined
                          ? property.daysOnMarket
                          : property.listDate
                          ? Math.floor(
                              (new Date() - new Date(property.listDate)) /
                                (1000 * 60 * 60 * 24)
                            )
                          : ""
                      }`
                }`
              : `DOM${property.listDate ? "*" : ""}: ${
                  property.daysOnMarket !== null &&
                  property.daysOnMarket !== undefined
                    ? property.daysOnMarket
                    : property.listDate
                    ? Math.floor(
                        (new Date() - new Date(property.listDate)) /
                          (1000 * 60 * 60 * 24)
                      )
                    : ""
                }`}
          </div>

          <div className="flex space-x-4">
            {fetchedImages.length > 0 ? (
              <ImageCarousel images={fetchedImages} isGridTile={true} />
            ) : (
              <img
                src={noImage}
                alt="Loading..."
                className="w-full h-full object-cover aspect-[4/3]"
              />
            )}
          </div>
        </div>

        {/* Property Details */}
        <div className="p-2">
          {/* Property Price */}
          <div className="text-center text-white text-md font-bold px-1 py-1">
            {isSold ? (
              <div className="flex flex-col bg-[#0A2342] justify-center items-center">
                <p className="text-lg font-bold text-white overflow-auto my-1 whitespace-normal">
                  SOLD - ${parseFloat(property.soldPrice).toLocaleString()}
                </p>
                {property.listPrice &&
                  (() => {
                    const soldPrice = parseFloat(property.soldPrice);
                    const listPrice = parseFloat(property.listPrice);
                    const differencePercentage = Math.round(
                      ((soldPrice - listPrice) / listPrice) * 100
                    );

                    if (differencePercentage > 0) {
                      return (
                        <p className="text-xs text-green-500 mb-2">
                          {`${differencePercentage}% over asking`}
                        </p>
                      );
                    } else if (differencePercentage < 0) {
                      return (
                        <p className="text-xs text-red-500 mb-2">
                          {`${Math.abs(differencePercentage)}% under asking`}
                        </p>
                      );
                    }

                    return null;
                  })()}
              </div>
            ) : (
              <div className="flex border-4 border-[#0A2342] justify-center items-center overflow-x-auto">
                <p className="flex items-center align-center text-lg font-bold text-[#0A2342] whitespace-normal px-2">
                  {property.listPrice
                    ? `$${parseFloat(property.listPrice).toLocaleString()}`
                    : "Price unavailable"}{" "}
                  {property.originalPrice &&
                    parseFloat(property.originalPrice) !==
                      parseFloat(property.listPrice) && (
                      <p className="text-sm text-gray-400 line-through ml-2">
                        ${parseFloat(property.originalPrice).toLocaleString()}
                      </p>
                    )}
                </p>
              </div>
            )}
          </div>
          {/* Property Address */}
          <div className="overflow-hidden">
            <p className="whitespace-nowrap text-md font-semibold text-[#0A2342] mt-1 overflow-hidden text-ellipsis">
              {property.address.streetNumber &&
              property.address.streetName &&
              property.address.streetSuffix
                ? `${
                    property.address.unitNumber
                      ? `${property.address.unitNumber} - `
                      : ""
                  }${property.address.streetNumber} ${
                    property.address.streetName
                  } ${property.address.streetSuffix}`
                : "Address unavailable"}
              {property.address.city ? `, ${property.address.city}` : ""}
            </p>
          </div>

          {/* Property Type */}
          <div className="overflow-hidden">
            <h3 className="whitespace-nowrap text-sm text-gray-600 mt-1 overflow-hidden text-ellipsis">
              {property.details.propertyType
                ? property.details.propertyType === "Att/Row/Townhouse"
                  ? "Attached"
                  : property.details.propertyType
                : "Property type unavailable"}{" "}
              {property.address.neighborhood
                ? `- ${property.address.neighborhood}`
                : ""}
            </h3>
          </div>

          {(property.details.numBedrooms !== null ||
            property.details.numBathrooms !== null ||
            property.details.sqft !== null) && (
            <div className="flex justify-between mt-1">
              <p className="text-sm text-gray-600">
                {property.details.numBedrooms || 0} Beds •{" "}
                {property.details.numBathrooms || 0} Baths{" "}
                {property.details.sqft ? `• ${property.details.sqft} sqft` : ""}
              </p>
            </div>
          )}
          {/* MLS Number and Brokerage Name */}
          <div className="flex justify-between mt-1">
            <p className="text-[10px] text-gray-600 truncate overflow-hidden whitespace-nowrap">
              {property.mlsNumber
                ? `MLS® #${property.mlsNumber}`
                : "MLS# unavailable"}{" "}
              {property.office && property.office.brokerageName
                ? `• ${property.office.brokerageName}`
                : ""}
            </p>
          </div>
        </div>
      </motion.div>

      {/* Favorites Modal */}
      {showFavoritesModal && (
        <AddToFavoritesModal
          property={property}
          onClose={() => setShowFavoritesModal(false)}
        />
      )}

      {showRemoveModal && (
        <RemoveFromFavoritesModal
          property={property}
          onClose={() => setShowRemoveModal(false)}
        />
      )}
    </>
  );
};

export default PropertyGridTile;
