const LoadingSpinner = ({ title, description }) => {
  return (
    <div className="z-10 flex flex-col items-center justify-center space-y-4">
      <div>
        <h3 className="text-xl font-semibold text-[#0A2342]">{title}</h3>
      </div>
      <div className="flex space-x-2">
        <div className="h-1.5 w-1.5 bg-[#0A2342] animate-bounce [animation-delay:-0.3s] [transform:scale(2.5)]"></div>
        <div className="h-1.5 w-1.5 bg-[#0A2342] animate-bounce [animation-delay:-0.15s] [transform:scale(2.5)]"></div>
        <div className="h-1.5 w-1.5 bg-[#0A2342] animate-bounce [transform:scale(1.5)]"></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
