import React from "react";
import {
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from "recharts";
import { useMediaQuery } from "react-responsive";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
} from "@heroicons/react/24/outline";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const listPrice =
      payload.find((item) => item.name === "List Price")?.value || 0;
    const soldPrice =
      payload.find((item) => item.name === "Sold Price")?.value || 0;
    const daysOnMarket =
      payload.find((item) => item.name === "Days on Market")?.value || 0;

    const delta =
      listPrice && soldPrice ? ((soldPrice - listPrice) / listPrice) * 100 : 0;
    const isPositive = delta >= 0;

    const dataItems = [
      {
        label: "List Price",
        value: listPrice === 0 ? "N/A" : `$${listPrice.toLocaleString()}`,
        color: "#0A2342",
      },
      {
        label: "Sold Price",
        value: soldPrice === 0 ? "N/A" : `$${soldPrice.toLocaleString()}`,
        color: "#E5B13A",
      },
      {
        label: "Days on Market",
        value: daysOnMarket === 0 ? "N/A" : daysOnMarket.toLocaleString(),
        color: "#0A2342",
      },
    ];

    return (
      <div className="bg-gray-100 border border-[#0A2342] p-3 shadow-md w-[200px]">
        <p className="text-gray-700 font-bold">
          {label
            ? new Date(label).toLocaleString("default", {
                month: "short",
                year: "numeric",
              })
            : "Invalid Date"}
        </p>
        {dataItems.map((item, index) => (
          <div key={index} className="flex items-center mt-2">
            <div
              className="h-2.5 w-2.5 rounded-sm"
              style={{ backgroundColor: item.color }}></div>
            <span className="ml-2 text-sm text-gray-700">{item.label}</span>
            <span className="ml-auto text-sm text-gray-800 font-medium">
              {item.value}
            </span>
          </div>
        ))}
        {listPrice !== 0 && soldPrice !== 0 && (
          <div className="mt-3 flex items-center border-t pt-2 text-sm text-[#0A2342] font-medium">
            List vs Sold:
            <div className="ml-auto flex items-center text-sm">
              <span className={isPositive ? "text-green-500" : "text-red-500"}>
                {`${Math.abs(delta).toFixed(1)}%`}
              </span>
              {isPositive ? (
                <ArrowTrendingUpIcon className="inline-block h-5 w-5 ml-1 text-green-500" />
              ) : (
                <ArrowTrendingDownIcon className="inline-block h-5 w-5 ml-1 text-red-500" />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
};

const MarketStatisticsChart = ({ property, marketStatistics }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  if (!marketStatistics || !marketStatistics.statistics) {
    return <p>No market statistics available.</p>;
  }

  const { listPrice, soldPrice, daysOnMarket } = marketStatistics.statistics;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const allMonths = new Set([
    ...Object.keys(listPrice.mth),
    ...Object.keys(soldPrice.mth),
    ...Object.keys(daysOnMarket.mth),
  ]);

  const chartData = Array.from(allMonths)
    .map((month) => {
      const [year, monthIndex] = month.split("-").map(Number);
      return {
        month: new Date(year, monthIndex - 1),
        listPrice: listPrice.mth[month]?.avg || 0,
        soldPrice: soldPrice.mth[month]?.avg || 0,
        daysOnMarket: daysOnMarket.mth[month]?.avg || 0,
      };
    })
    .filter(
      (data) =>
        data.month.getFullYear() < currentYear ||
        (data.month.getFullYear() === currentYear &&
          data.month.getMonth() <= currentMonth)
    )
    .filter(
      (data) =>
        data.listPrice > 0 || data.soldPrice > 0 || data.daysOnMarket > 0
    );

  return (
    <div
      className={`p-4 shadow-md ${
        isSmallScreen
          ? "flex-col justify-center items-center"
          : "flex-col justify-center p-4"
      }`}>
      <div className="mb-4">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="pb-4">
            <h2 className="text-xl font-semibold text-[#0A2342] mb-4 md:mb-0">
              List vs Sold Prices
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Showing average market trends for {property.details.numBedrooms}{" "}
              bedrooms {property.details.numBathrooms} bathrooms in{" "}
              {property.address.city}.
            </p>
          </div>
          <div className="flex border border-[#0A2342] p-3 flex-wrap justify-center gap-x-6 gap-y-4 md:flex md:ml-auto">
            <div className="flex flex-col items-center">
              <dt className="text-sm font-semibold text-gray-700">Avg List</dt>
              <dd className="text-xl font-bold text-[#0A2342]">
                ${marketStatistics.statistics.listPrice.avg.toLocaleString()}
              </dd>
            </div>
            <div className="flex flex-col items-center">
              <dt className="text-sm font-semibold text-gray-700">Avg Sold</dt>
              <dd className="text-xl font-bold text-[#0A2342]">
                ${marketStatistics.statistics.soldPrice.avg.toLocaleString()}
              </dd>
            </div>
            <div className="flex flex-col items-center">
              <dt className="text-sm font-semibold text-gray-700">Avg DOM</dt>
              <dd className="text-xl font-bold text-[#0A2342]">
                {marketStatistics.statistics.daysOnMarket.avg} days
              </dd>
            </div>
          </div>
        </div>

        {/* <div className="mt-2 text-sm text-gray-600">
          <p>
            Showing average market trends for {property.details.numBedrooms}{" "}
            bedrooms {property.details.numBathrooms} bathrooms in{" "}
            {property.address.city}.
          </p>
        </div> */}
      </div>

      <div className="flex justify-center">
        <ResponsiveContainer
          width={isSmallScreen ? 300 : "100%"}
          height={isSmallScreen ? 400 : 400}
          className="flex justify-center">
          <ComposedChart
            data={chartData}
            margin={
              isSmallScreen
                ? { top: 0, right: -18, left: -18, bottom: 0 }
                : { top: 20, right: -10, left: 0, bottom: 10 }
            }>
            <XAxis
              dataKey="month"
              tickFormatter={(month, index) => {
                return index % 6 === 0
                  ? new Date(month).toLocaleString("default", {
                      month: "short",
                      year: "numeric",
                    })
                  : "";
              }}
              interval={0}
              axisLine={!isSmallScreen} // Show/hide axis line based on screen size
              tick={!isSmallScreen} // Hide ticks on small screens
              tickLine={!isSmallScreen} // Hide tick lines on small screens
              label={
                !isSmallScreen ? { value: "", position: "insideBottom" } : null
              } // Hide labels on small screens
              hide={isSmallScreen} // Completely hide the axis on small screens
            />

            <YAxis
              tickFormatter={(value) => {
                if (value === 0) {
                  return ""; // Do not render tick for zero
                } else if (value >= 1000000) {
                  // If value is 1 million or more, format it as X.XM
                  return `$${(value / 1000000).toFixed(1)}M`;
                } else if (value >= 1000) {
                  // If value is 1 thousand or more, format it as XXXk
                  return `$${(value / 1000).toFixed(0)}k`;
                }
                return `$${value.toLocaleString()}`; // Default format for smaller numbers
              }}
              axisLine={!isSmallScreen} // Show/hide axis line based on screen size
              tick={!isSmallScreen} // Hide ticks on small screens
              tickLine={!isSmallScreen} // Hide tick lines on small screens
              hide={isSmallScreen} // Completely hide the axis on small screens
            />

            <YAxis
              yAxisId="right"
              orientation="right"
              tickFormatter={(value) =>
                value > 0 ? `${value.toLocaleString()}d` : ""
              }
              axisLine={!isSmallScreen} // Show/hide axis line based on screen size
              tick={!isSmallScreen} // Hide ticks on small screens
              tickLine={!isSmallScreen} // Hide tick lines on small screens
              hide={isSmallScreen} // Completely hide the axis on small screens
            />

            <Tooltip content={<CustomTooltip />} />
            <Legend
              formatter={(value) => {
                if (value === "listPrice") return "List Price";
                if (value === "soldPrice") return "Sold Price";
                if (value === "daysOnMarket") return "Days on Market";
                return value;
              }}
              layout="horizontal"
              align="center"
            />
            <Area
              type="monotone"
              dataKey="listPrice"
              stroke="#0A2342"
              fill="#0A2342"
              fillOpacity={0.6}
              name="List Price"
              connectNulls={true}
            />
            <Area
              type="monotone"
              dataKey="soldPrice"
              stroke="#E5B13A"
              fill="#E5B13A"
              fillOpacity={0.6}
              name="Sold Price"
              connectNulls
            />
            <Bar
              yAxisId="right"
              dataKey="daysOnMarket"
              fill="#0A2342"
              name="Days on Market"
              barSize={8}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MarketStatisticsChart;
