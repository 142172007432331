import { createSlice } from "@reduxjs/toolkit";
import {
  fetchFavoritesListsThunk,
  createFavoritesListThunk,
  updateFavoritesListThunk,
  deleteFavoritesListThunk,
  addPropertyToListThunk,
  removePropertyFromListThunk,
} from "../thunks/favoritesThunk";

const initialState = {
  lists: [],
  status: "idle",
  error: null,
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    // You can include specific reducers if needed later
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavoritesListsThunk.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchFavoritesListsThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.lists = action.payload;
      })
      .addCase(fetchFavoritesListsThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })

      .addCase(createFavoritesListThunk.pending, (state) => {
        state.error = null;
      })
      .addCase(createFavoritesListThunk.fulfilled, (state, action) => {
        state.lists.push(action.payload);
      })
      .addCase(createFavoritesListThunk.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })

      .addCase(updateFavoritesListThunk.fulfilled, (state, action) => {
        const index = state.lists.findIndex(
          (list) => list._id === action.payload._id
        );
        if (index !== -1) {
          state.lists[index] = action.payload;
        }
      })
      .addCase(updateFavoritesListThunk.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })

      .addCase(deleteFavoritesListThunk.fulfilled, (state, action) => {
        state.lists = state.lists.filter((list) => list._id !== action.payload); // Remove the deleted list
      })
      .addCase(deleteFavoritesListThunk.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })

      .addCase(addPropertyToListThunk.fulfilled, (state, action) => {
        const { listId, property } = action.payload;

        console.log("received this listId and property", listId, property);
        const list = state.lists.find((list) => list._id === listId);
        if (list) {
          list.properties.push(property); // Add property to the list
        }
      })
      .addCase(addPropertyToListThunk.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })

      .addCase(removePropertyFromListThunk.fulfilled, (state, action) => {
        const { listId, propertyId } = action.payload;
        const list = state.lists.find((list) => list._id === listId);
        if (list) {
          list.properties = list.properties.filter(
            (property) => property.mlsNumber !== propertyId
          );
        }
      })
      .addCase(removePropertyFromListThunk.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      });
  },
});

export default favoritesSlice.reducer;
