import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import {
  fetchFavoritesListsThunk,
  createFavoritesListThunk,
  updateFavoritesListThunk,
  deleteFavoritesListThunk,
  fetchFullFavoritesListThunk,
  removePropertyFromListThunk,
} from "../store/thunks/favoritesThunk";
import {
  PlusIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import PropertyRectangularCard from "../components/properties/PropertyRectangularCard";
import LoadingSpinner from "../components/LoadingSpinner";

const FavouritesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lists = useSelector((state) => state.favorites.lists);
  const [selectedList, setSelectedList] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showNewListModal, setShowNewListModal] = useState(false);
  const [newListData, setNewListData] = useState({ name: "", description: "" });
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);
  const [fullProperties, setFullProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 9;

  // Calculate the total pages dynamically
  const totalPages = Math.ceil(fullProperties.length / pageSize);

  useEffect(() => {
    dispatch(fetchFavoritesListsThunk());
  }, [dispatch]);

  const handleListSelect = async (list) => {
    setSelectedList(list);
    setIsLoadingProperties(true);
    try {
      const fullList = await dispatch(
        fetchFullFavoritesListThunk(list._id)
      ).unwrap();
      setFullProperties(fullList.properties);
      setCurrentPage(1); // Reset to the first page on new list selection
    } catch (error) {
      console.error("Error fetching full list:", error);
    } finally {
      setIsLoadingProperties(false);
    }
  };

  const handleCreateList = async () => {
    try {
      await dispatch(
        createFavoritesListThunk({
          name: newListData.name,
          description: newListData.description,
        })
      ).unwrap();
      setShowNewListModal(false);
      setNewListData({ name: "", description: "" });
      dispatch(fetchFavoritesListsThunk());
    } catch (error) {
      console.error("Failed to create list:", error);
    }
  };

  const handleUpdateList = async () => {
    try {
      await dispatch(
        updateFavoritesListThunk({
          listId: selectedList._id,
          updates: {
            name: selectedList.name,
            description: selectedList.description,
          },
        })
      ).unwrap();
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update list:", error);
    }
  };

  const handleDeleteList = async (listId) => {
    if (window.confirm("Are you sure you want to delete this list?")) {
      try {
        await dispatch(deleteFavoritesListThunk(listId)).unwrap();
        setSelectedList(null);
      } catch (error) {
        console.error("Failed to delete list:", error);
      }
    }
  };

  const handleDeleteProperty = async (propertyId) => {
    try {
      await dispatch(
        removePropertyFromListThunk({
          listId: selectedList._id,
          propertyId,
        })
      ).unwrap();
      setFullProperties((prev) =>
        prev.filter((property) => property.mlsNumber !== propertyId)
      );
    } catch (error) {
      console.error("Failed to delete property:", error);
    }
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Derive paginated properties based on current page and page size
  const paginatedProperties = fullProperties.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-[#0A2342]">My Favorites</h1>
        <button
          onClick={() => setShowNewListModal(true)}
          className="flex items-center px-4 py-2 bg-[#0A2342] text-white hover:bg-opacity-90">
          <PlusIcon className="h-5 w-5 mr-2" />
          New List
        </button>
      </div>

      {/* Lists Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {lists.map((list) => (
          <motion.div
            key={list._id}
            whileHover={{ scale: 1.02 }}
            className={`p-4 border cursor-pointer ${
              selectedList?._id === list._id
                ? "bg-[#0A2342] text-white"
                : "bg-white"
            }`}
            onClick={() => handleListSelect(list)}>
            <div className="flex flex-col justify-between h-full">
              <h3 className="font-semibold">{list.name}</h3>
              <p className="text-sm mt-2">
                {list.properties.length} properties
              </p>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Message when no list is selected */}
      {!selectedList && (
        <div className="mt-8 p-4 border border-gray-300 text-center text-gray-600">
          Select a list to view favorite properties
        </div>
      )}

      {/* Selected List Content */}
      {selectedList && (
        <div className="mt-8">
          <div className="flex justify-between items-center mb-6">
            {isEditing ? (
              <div className="space-y-2">
                <input
                  type="text"
                  value={selectedList.name}
                  onChange={(e) =>
                    setSelectedList({ ...selectedList, name: e.target.value })
                  }
                  className="text-2xl font-bold px-2 py-1 border w-full"
                  placeholder="List Name"
                />
                <textarea
                  value={selectedList.description}
                  onChange={(e) =>
                    setSelectedList({
                      ...selectedList,
                      description: e.target.value,
                    })
                  }
                  className="px-2 py-1 border w-full"
                  placeholder="List Description"
                />
              </div>
            ) : (
              <div>
                <h2 className="text-2xl font-bold">{selectedList.name}</h2>
                <p className="text-md mt-2">{selectedList.description}</p>
              </div>
            )}
            <div className="space-x-2">
              {isEditing && (
                <button
                  onClick={handleUpdateList}
                  className="px-4 py-2 bg-[#0A2342] text-white">
                  Save
                </button>
              )}
              <button
                onClick={() => setIsEditing(!isEditing)}
                className="p-2 hover:bg-gray-200">
                <PencilIcon className="h-5 w-5" />
              </button>
              <button
                onClick={() => handleDeleteList(selectedList._id)}
                className="p-2 text-red-500 hover:bg-gray-200">
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
          </div>

          {isLoadingProperties ? (
            <div className="flex justify-center items-center h-64">
              <LoadingSpinner />
            </div>
          ) : paginatedProperties.length === 0 ? (
            <div className="flex justify-center items-center h-64">
              <button
                onClick={() => navigate("/discover")}
                className="text-lg text-[#0A2342] hover:text-indigo-800">
                Start adding to this list
              </button>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {paginatedProperties.map((property) => (
                  <div key={property.mlsNumber} className="relative">
                    {isEditing && (
                      <button
                        onClick={() => handleDeleteProperty(property.mlsNumber)}
                        className="absolute top-0 right-0 p-2 text-red-500 hover:text-red-700">
                        <XCircleIcon className="h-6 w-6" />
                      </button>
                    )}
                    <PropertyRectangularCard property={property} />
                    <p>{property.notes}</p>
                    <p>{property.nickname}</p>
                  </div>
                ))}
              </div>
              <div className="flex justify-between items-center mt-8 px-4">
                <button
                  className="flex items-center space-x-2 text-[#0A2342] disabled:opacity-50"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}>
                  <ArrowLeftIcon className="w-4 h-4" />
                  <span className="text-sm">Previous</span>
                </button>
                <span className="text-sm text-[#0A2342]">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="flex items-center space-x-2 text-[#0A2342] disabled:opacity-50"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}>
                  <span className="text-sm">Next</span>
                  <ArrowRightIcon className="w-4 h-4" />
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {/* New List Modal */}
      {showNewListModal && (
        <div className="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white p-6 w-full max-w-md">
            <h2 className="text-2xl font-bold text-[#0A2342] mb-4">
              Create New List
            </h2>
            <input
              type="text"
              placeholder="List Name"
              value={newListData.name}
              onChange={(e) =>
                setNewListData({ ...newListData, name: e.target.value })
              }
              className="w-full p-2 border mb-4"
            />
            <textarea
              placeholder="Description (optional)"
              value={newListData.description}
              onChange={(e) =>
                setNewListData({ ...newListData, description: e.target.value })
              }
              className="w-full p-2 border mb-4"
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowNewListModal(false)}
                className="px-4 py-2 border">
                Cancel
              </button>
              <button
                onClick={handleCreateList}
                className="px-4 py-2 bg-[#0A2342] text-white">
                Create
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FavouritesPage;
