import React, { useState, useEffect } from "react";
import * as Slider from "@radix-ui/react-slider";
import { XMarkIcon } from "@heroicons/react/24/outline";

const FilterBar = ({ onFiltersChange }) => {
  const defaultFilters = {
    priceRange: [100000, 5000000],
    bedroomRange: 0,
    bathroomRange: 0,
    squareFootageRange: [1, 5000],
    resultsPerPage: 18,
    currentPage: 1,
    forSale: true,
    sold: false,
  };

  const [priceRange, setPriceRange] = useState(defaultFilters.priceRange);
  const [bedroomRange, setBedroomRange] = useState(defaultFilters.bedroomRange);
  const [bathroomRange, setBathroomRange] = useState(
    defaultFilters.bathroomRange
  );
  const [squareFootageRange, setSquareFootageRange] = useState(
    defaultFilters.squareFootageRange
  );
  const [resultsPerPage, setResultsPerPage] = useState(
    defaultFilters.resultsPerPage
  );
  const [currentPage, setCurrentPage] = useState(defaultFilters.currentPage);
  const [forSale, setForSale] = useState(defaultFilters.forSale);
  const [sold, setSold] = useState(defaultFilters.sold);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    handleFilterChange();
  }, []);

  const handleFilterChange = () => {
    setCurrentPage(1); // Reset page number
    setResultsPerPage(18); // Reset results per page

    const filters = {
      minPrice: priceRange[0],
      maxPrice: priceRange[1],
      minBeds: bedroomRange,
      minBaths: bathroomRange,
      minSqft: squareFootageRange[0],
      maxSqft: squareFootageRange[1],
      resultsPerPage: 18,
      pageNum: 1,
    };

    if (sold) {
      const today = new Date();
      const minSoldDate = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD
      filters.status = "U";
      filters.lastStatus = "Sld";
      filters.minSoldDate = minSoldDate;
    } else if (forSale) {
      filters.status = "A";
    }

    const uriEncodedFilters = new URLSearchParams(filters).toString();
    onFiltersChange(uriEncodedFilters);
  };

  const resetFilters = () => {
    setPriceRange(defaultFilters.priceRange);
    setBedroomRange(defaultFilters.bedroomRange);
    setBathroomRange(defaultFilters.bathroomRange);
    setSquareFootageRange(defaultFilters.squareFootageRange);
    setResultsPerPage(defaultFilters.resultsPerPage);
    setCurrentPage(defaultFilters.currentPage);
    setForSale(defaultFilters.forSale);
    setSold(defaultFilters.sold);
    handleFilterChange();
  };

  const handleSoldChange = (e) => {
    const isSold = e.target.checked;
    setSold(isSold);
    if (isSold) {
      setForSale(false);
    }
    handleFilterChange();
  };

  return (
    <div>
      {/* Open Filters Button */}
      <div className="z-20 fixed w-full bg-white shadow-md border-b border-[#0A2342] px-4 py-2 flex justify-between items-center z-10">
        <h1 className="text-lg font-semibold text-[#0A2342]">
          Property Listings
        </h1>
        <div className="flex items-center space-x-4"></div>
        <div className="flex gap-2">
          <button
            onClick={() => setIsModalOpen(true)}
            className="text-white bg-[#0A2342] hover:bg-[#082132] px-4 py-2  text-sm font-medium">
            Filters
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white shadow-lg w-11/12 md:w-2/3 lg:w-1/2 max-h-[90vh] overflow-y-auto p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-bold text-gray-800">Filters</h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700">
                <XMarkIcon className="h-4 w-4 text-gray-500 hover:text-gray-700" />
              </button>
            </div>
            {/* Filters */}
            <div className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Price Range */}
                <div className="flex flex-col">
                  <label
                    htmlFor="price-range"
                    className="text-sm font-medium text-gray-700 pb-1">
                    Price Range
                  </label>
                  <Slider.Root
                    className="relative flex items-center select-none touch-none w-full h-4"
                    value={priceRange}
                    onValueChange={(value) => {
                      setPriceRange(value);
                      handleFilterChange();
                    }}
                    min={100000}
                    max={5000000}
                    step={100000}>
                    <Slider.Track className="bg-gray-300 relative grow h-[6px]">
                      <Slider.Range className="absolute bg-[#0A2342] h-full" />
                    </Slider.Track>
                    <Slider.Thumb className="block w-4 h-4 bg-white border border-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                    <Slider.Thumb className="block w-4 h-4 bg-white border border-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                  </Slider.Root>
                  <div className="text-xs text-gray-500 mt-1">
                    ${priceRange[0].toLocaleString()} - $
                    {priceRange[1].toLocaleString()}
                  </div>
                </div>

                {/* Square Footage */}
                <div className="flex flex-col">
                  <label
                    htmlFor="square-footage"
                    className="text-sm font-medium text-gray-700 pb-1">
                    Square Footage
                  </label>
                  <Slider.Root
                    className="relative flex items-center select-none touch-none w-full h-4"
                    value={squareFootageRange}
                    onValueChange={(value) => {
                      setSquareFootageRange(value);
                      handleFilterChange();
                    }}
                    min={1}
                    max={5000}
                    step={100}>
                    <Slider.Track className="bg-gray-300 relative grow h-[6px]">
                      <Slider.Range className="absolute bg-[#0A2342] h-full" />
                    </Slider.Track>
                    <Slider.Thumb className="block w-4 h-4 bg-white border border-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                    <Slider.Thumb className="block w-4 h-4 bg-white border border-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                  </Slider.Root>
                  <div className="text-xs text-gray-500 mt-1">
                    {squareFootageRange[0]} - {squareFootageRange[1]} sqft
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Bedrooms */}
                <div className="flex flex-col">
                  <label
                    htmlFor="bedrooms"
                    className="text-sm font-medium text-gray-700 pb-1">
                    Bedrooms
                  </label>
                  <div className="flex space-x-1">
                    {["Any", "1", "2", "3", "4", "5+"].map((label, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          setBedroomRange(index === 0 ? 0 : index);
                          handleFilterChange();
                        }}
                        className={`text-xs px-2.5 py-1 border ${
                          bedroomRange === (index === 0 ? 0 : index)
                            ? "bg-[#0A2342] text-white"
                            : "bg-gray-100"
                        }`}>
                        {label}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Bathrooms */}
                <div className="flex flex-col">
                  <label
                    htmlFor="bathrooms"
                    className="text-sm font-medium text-gray-700 pb-1">
                    Bathrooms
                  </label>
                  <div className="flex space-x-1">
                    {["Any", "1", "2", "3", "4", "5+"].map((label, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          setBathroomRange(index === 0 ? 0 : index);
                          handleFilterChange();
                        }}
                        className={`text-xs px-2.5 py-1 border ${
                          bathroomRange === (index === 0 ? 0 : index)
                            ? "bg-[#0A2342] text-white"
                            : "bg-gray-100"
                        }`}>
                        {label}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex space-x-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={forSale}
                    onChange={(e) => {
                      setForSale(e.target.checked);
                      handleFilterChange();
                    }}
                    className="mr-2"
                  />
                  For Sale
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={sold}
                    onChange={(e) => {
                      setSold(e.target.checked);
                      handleFilterChange();
                    }}
                    className="mr-2"
                  />
                  Sold
                </label>
              </div>
            </div>
            {/* Close & Apply */}
            <div className="flex justify-between space-x-4 mt-6">
              <button
                onClick={resetFilters}
                className="space-x-4 mt-6 px-4 py-2 text-sm bg-gray-200 hover:bg-gray-300 text-gray-700">
                Clear All
              </button>
              <div className="flex justify-end space-x-4 mt-6">
                <button
                  onClick={() => {
                    handleFilterChange();
                    setIsModalOpen(false);
                  }}
                  className="px-4 py-2 text-sm bg-[#0A2342] hover:bg-[#082132] text-white">
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterBar;
