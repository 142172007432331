import React, { useState, useMemo } from "react";
import HistoryTable from "./HistoryTable";
import HistoryChart from "./HistoryChart";

const PropertyHistory = ({ history }) => {
  const [viewMode, setViewMode] = useState("table");

  // Separate sale and lease histories
  const { saleHistory, leaseHistory } = useMemo(() => {
    return {
      saleHistory: history.filter(item => item.type === "Sale"),
      leaseHistory: history.filter(item => item.type === "Lease")
    };
  }, [history]);

  return (
    <div className="">
      {/* <div className="flex gap-4 pb-4">
        <button
          className={`px-4 py-2 ${
            viewMode === "table" ? "bg-[#0A2342] text-white" : "bg-gray-300"
          }`}
          onClick={() => setViewMode("table")}
          style={{ transition: "transform 0.2s" }}
          onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}>
          Table View
        </button>
        <button
          className={`px-4 py-2 ${
            viewMode === "chart" ? "bg-[#0A2342] text-white" : "bg-gray-300"
          }`}
          onClick={() => setViewMode("chart")}
          style={{ transition: "transform 0.2s" }}
          onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}>
          Chart View
        </button>
      </div> */}

      <div className="space-y-8">
        {/* Sale History Section */}
        {saleHistory.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold mb-4 text-[#0A2342]">Sale History</h3>
            <div className="overflow-auto max-h-[400px]">
              {viewMode === "table" ? (
                <HistoryTable history={saleHistory} />
              ) : (
                <HistoryChart history={saleHistory} />
              )}
            </div>
          </div>
        )}

        {/* Lease History Section */}
        {leaseHistory.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold mb-4 text-[#0A2342]">Lease History</h3>
            <div className="overflow-auto max-h-[400px]">
              {viewMode === "table" ? (
                <HistoryTable history={leaseHistory} />
              ) : (
                <HistoryChart history={leaseHistory} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyHistory;
