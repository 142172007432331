import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendContactEmailThunk } from "../store/thunks/emailThunk";
import { resetEmailState } from "../store/slices/emailSlice";

const RealtorPage = () => {
  const [activeRealtor, setActiveRealtor] = useState(null);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const { loading, error, successMessage } = useSelector(
    (state) => state.email
  );

  const realtors = [
    {
      name: "Terrence Marshall, CPA",
      image: require("../assets/terrence.jpg"),
      blurb: `Terrence Marshall is an experienced realtor who has assisted families and investors in their real estate journey, facilitating transactions with a value of over $100 million. Leveraging his background as a Chartered Professional Accountant (CPA) with expertise in income tax audit, HST audit, and public accounting, Terrence offers a distinct advantage in the real estate industry.`,
    },
    {
      name: "Ibrahim Abowath",
      image: require("../assets/ibrahim.png"),
      blurb: `Ibrahim Abowath is an experienced real estate agent with over five years of experience, specializing in both new homes and resale. Ibrahim has successfully assisted numerous families with their real estate needs, earning him a ranking among the top 3% of agents in Toronto.`,
    },
    {
      name: "Heaven Kaymen Joseph",
      image: require("../assets/heaven.jpg"),
      blurb: `Heaven Kaymen is your trusted real estate connector. With a passion for people and a knack for forging strong connections, Heaven has been a dedicated people connector for over two decades.`,
    },
  ];

  const handleContactClick = (index) => {
    setActiveRealtor(activeRealtor === index ? null : index);
    dispatch(resetEmailState());
  };

  const handleSubmit = async (e, realtorIndex) => {
    e.preventDefault();

    const formData = {
      userName: e.target.elements.name.value,
      userEmail: e.target.elements.email.value,
      message: e.target.elements.message.value,
      realtorName: realtors[realtorIndex].name,
      realtorEmail: realtors[realtorIndex].email,
    };

    try {
      await dispatch(sendContactEmailThunk(formData)).unwrap();
      e.target.reset();
      setTimeout(() => {
        setActiveRealtor(null);
        dispatch(resetEmailState());
      }, 3000);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-3xl font-bold text-[#0A2342] my-6 text-center">
        Meet Our Realtors
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full max-w-4xl">
        {realtors.map((realtor, index) => (
          <div key={index} className="bg-white p-4 shadow-md text-center">
            <img
              src={realtor.image}
              alt={realtor.name}
              width={128}
              height={128}
              className="mx-auto mb-4 border-2 border-[#0A2342]"
            />
            <h2 className="text-xl font-semibold text-[#0A2342]">
              {realtor.name}
            </h2>
            <p className="text-gray-600 mb-4">{realtor.blurb}</p>
            <button
              onClick={() => handleContactClick(index)}
              className="bg-[#0A2342] text-white py-2 px-4">
              Get in Touch
            </button>
            {activeRealtor === index && (
              <form className="mt-4" onSubmit={(e) => handleSubmit(e, index)}>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  className="border border-gray-300 p-2 w-full mb-2"
                  required
                  defaultValue={
                    user ? `${user.firstName} ${user.lastName}` : ""
                  }
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  className="border border-gray-300 p-2 w-full mb-2"
                  required
                  defaultValue={user?.username || ""}
                />
                <textarea
                  name="message"
                  placeholder="Your Message"
                  className="border border-gray-300 p-2 w-full mb-2"
                  rows="3"
                  required
                />
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-[#E5B13A] text-[#0A2342] py-2 px-4 hover:bg-[#E5B13A]/90 disabled:opacity-50">
                  {loading ? "Sending..." : "Send Message"}
                </button>
                {successMessage && (
                  <p className="text-green-600 mt-2">{successMessage}</p>
                )}
                {error && <p className="text-red-600 mt-2">{error}</p>}
              </form>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RealtorPage;
