import React from "react";

const HistoryTable = ({ history }) => {
  // Define a mapping object
  const statusTranslation = {
    New: "New",
    Ter: "Terminated",
    Sld: "Sold",
    Lsd: "Leased",
  };

  // Helper function to calculate time since listing
  const getTimeSince = (date) => {
    const now = new Date();
    const listDate = new Date(date);
    const diffInMs = now - listDate;

    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) return `${years} year${years > 1 ? "s" : ""} ago`;
    if (months > 0) return `${months} month${months > 1 ? "s" : ""} ago`;
    return `${days} day${days > 1 ? "s" : ""} ago`;
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse border border-[#0A2342]">
        <thead>
          <tr className="bg-gray-200 text-[#0A2342]">
            <th className="p-3 border border-[#0A2342]">MLS Number</th>
            <th className="p-3 border border-[#0A2342]">Type</th>
            <th className="p-3 border border-[#0A2342]">List Price</th>
            <th className="p-3 border border-[#0A2342]">List Date</th>
            <th className="p-3 border border-[#0A2342]">Days Ago</th>
            <th className="p-3 border border-[#0A2342]">Status</th>
            <th className="p-3 border border-[#0A2342]">Sold Price</th>
          </tr>
        </thead>
        <tbody>
          {history.map((item, idx) => (
            <tr key={idx} className={"bg-white"}>
              <td className="p-3 border border-[#0A2342]">{item.mlsNumber}</td>
              <td className="p-3 border border-[#0A2342]">{item.type}</td>
              <td className="p-3 border border-[#0A2342]">
                ${parseFloat(item.listPrice).toLocaleString()}
              </td>
              <td className="p-3 border border-[#0A2342]">
                {new Date(item.listDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </td>
              <td className="p-3 border border-[#0A2342]">
                {getTimeSince(item.listDate)}
              </td>
              <td className="p-3 border border-[#0A2342]">
                {item.lastStatus === "Sld" ? (
                  <>
                    {statusTranslation[item.lastStatus] || item.lastStatus}{" "}
                    <span className="">
                      ({item.soldDate ? item.soldDate.split("T")[0] : ""})
                    </span>
                  </>
                ) : (
                  statusTranslation[item.lastStatus] || item.lastStatus
                )}
              </td>

              {/* <td className="p-3 border border-[#0A2342]">
                {statusTranslation[item.lastStatus] || item.lastStatus}
              </td> */}
              <td className="p-3 border border-[#0A2342]">
                {item.soldPrice
                  ? `$${parseFloat(item.soldPrice).toLocaleString()}`
                  : "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HistoryTable;
