import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchImageThunk } from "../../store/thunks/repliersThunk";
import ImageCarousel from "./ImageCarousel";
import noImage from "../../assets/noimage.png";

const PropertyRectangularCard = ({ property }) => {
  const [fetchedImages, setFetchedImages] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (property && property.images && property.images.length > 0) {
      const fetchFirstImage = async () => {
        try {
          const firstImage = await dispatch(
            fetchImageThunk(property.images[0])
          ).unwrap();
          setFetchedImages([firstImage]);
        } catch (error) {
          console.error("Failed to fetch the first image:", error);
        }
      };
      fetchFirstImage();
    }
  }, [property, dispatch]);

  const createSlug = (address) => {
    if (!address) return "";

    const { streetNumber, streetName, city, neighborhood } = address;
    return [streetNumber, streetName, city, neighborhood]
      .filter(Boolean) // Remove undefined or null parts
      .join("-")
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, ""); // Remove invalid characters
  };

  const handleCardClick = () => {
    if (!property || !property.address) {
      console.error("Property or address is undefined:", property);
      return;
    }

    const slug = createSlug(property.address);
    const url = `/propertydetails/${property.mlsNumber}/${property.boardId}/${slug}`;
    window.open(url, "_blank");
  };

  return (
    <div
      onClick={handleCardClick}
      className="z-50 flex items-center border bg-white border-gray-300 shadow-md p-1.5 w-full max-w-lg cursor-pointer hover:bg-gray-100 transition-all duration-150 hover:scale-102"
      style={{ transition: "transform 0.15s ease-in-out" }}>
      <div className="w-2/3 pr-2 flex flex-col justify-between h-full">
        {/* Main Property Info */}
        <div>
          <div className="flex border-4 border-[#0A2342] justify-center items-center">
            <p className="text-lg font-bold text-[#0A2342] overflow-auto whitespace-normal px-2">
              {property.listPrice
                ? `$${parseFloat(property.listPrice).toLocaleString()}`
                : "Price unavailable"}
            </p>
            {property.originalPrice &&
              parseFloat(property.originalPrice) !==
                parseFloat(property.listPrice) && (
                <p className="text-sm text-gray-400 line-through ml-2">
                  ${parseFloat(property.originalPrice).toLocaleString()}
                </p>
              )}
          </div>
          <p className="text-sm font-semibold text-[#0A2342] mt-1">
            {property.address.unitNumber
              ? `${property.address.unitNumber} - `
              : ""}
            {property.address.streetNumber} {property.address.streetName}{" "}
            {property.address.streetSuffix}, {property.address.city}
          </p>
          <p className="text-sm text-gray-500 mt-1">
            {property.details.numBedrooms || 0} Beds •{" "}
            {property.details.numBathrooms || 0} Baths{" "}
            {property.details.sqft ? `• ${property.details.sqft} sqft` : ""}
          </p>
        </div>

        {/* Footer */}
        <div className="mt-2">
          <p className="text-[10px] text-gray-600 truncate overflow-hidden whitespace-nowrap">
            {property.mlsNumber
              ? `MLS® #${property.mlsNumber}`
              : "MLS# unavailable"}{" "}
            •{" "}
            {property.office && property.office.brokerageName
              ? property.office.brokerageName
              : "Brokerage unavailable"}
          </p>
        </div>
      </div>

      <div className="w-1/3">
        <div className="flex space-x-4">
          {fetchedImages.length > 0 ? (
            <ImageCarousel images={fetchedImages} isGridTile={true} />
          ) : (
            <img src={noImage} alt="Loading..." className="w-full h-auto" />
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyRectangularCard;
