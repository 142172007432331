import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

const General = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div>
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        Profile
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-500">
        This information was automatically gathered from your registration.
      </p>
      <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            First Name
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="text-gray-900">{user.firstName}</div>
            <button
              type="button"
              className="font-semibold text-[#0A2342] hover:text-gold-500">
              Update
            </button>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            Last Name
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="text-gray-900">{user.lastName}</div>
            <button
              type="button"
              className="font-semibold text-[#0A2342] hover:text-gold-500">
              Update
            </button>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            Username
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="text-gray-900">{user.username}</div>
            <button
              type="button"
              className="font-semibold text-[#0A2342] hover:text-gold-500">
              Update
            </button>
          </dd>
        </div>
      </dl>{" "}
    </div>
  );
};

export default General;
