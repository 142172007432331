import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";
import { fetchSingleListingThunk } from "./repliersThunk";

export const fetchFavoritesListsThunk = createAsyncThunk(
  "favorites/fetchLists",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token, user } = getState().auth;
      const userId = user?._id; // Ensure userId is extracted from the auth state
      if (!userId) {
        throw new Error("User ID is missing in the auth state.");
      }

      const config = generateConfig(token);

      console.log("Fetching favorites lists with userId:", userId);

      const response = await api.get(`/v1/api/favorites`, {
        ...config,
        params: { userId }, // Pass userId as a query parameter
      });

      return response.data; // Assuming the backend returns the list data
    } catch (error) {
      console.error("Error fetching favorites lists:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch favorites lists."
      );
    }
  }
);

export const fetchFullFavoritesListThunk = createAsyncThunk(
  "favorites/fetchFullList",
  async (listId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      // Fetch the enriched favorites list from the backend
      const response = await api.get(`/v1/api/favorites/${listId}`, config);

      return response.data; // Enriched list with full property details
    } catch (error) {
      console.error("Error fetching full favorites list:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch full favorites list."
      );
    }
  }
);

export const createFavoritesListThunk = createAsyncThunk(
  "favorites/createList",
  async (
    {
      name,
      description,
      properties = [],
      isPublic = false,
      tags = [],
      sortOrder = "addedAt", // Use a valid default value
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const { token, user } = getState().auth; // Retrieve token and user object
      const userId = user?._id; // Extract userId from user object
      if (!userId) {
        throw new Error("User ID is missing in the auth state.");
      }

      const config = generateConfig(token);

      console.log("Sending payload to backend:", {
        userId,
        name,
        description,
        properties,
        isPublic,
        tags,
        sortOrder,
      });

      const response = await api.post(
        "/v1/api/favorites",
        {
          userId, // Correctly include userId in the payload
          name,
          description,
          properties,
          isPublic,
          tags,
          sortOrder,
        },
        config
      );

      return response.data.list; // Assuming backend response includes { list }
    } catch (error) {
      console.error("Error creating favorites list:", error);
      return rejectWithValue(
        error.response?.data || "Failed to create favorites list."
      );
    }
  }
);

export const updateFavoritesListThunk = createAsyncThunk(
  "favorites/updateList",
  async ({ listId, updates }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.patch(
        `/v1/api/favorites/${listId}`,
        updates,
        config
      );

      return response.data.list; // Assuming the backend returns { list }
    } catch (error) {
      console.error("Error updating favorites list:", error);
      return rejectWithValue(
        error.response?.data || "Failed to update favorites list."
      );
    }
  }
);

export const deleteFavoritesListThunk = createAsyncThunk(
  "favorites/deleteList",
  async (listId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      await api.delete(`/v1/api/favorites/${listId}`, config);

      return listId; // Return the deleted list's ID
    } catch (error) {
      console.error("Error deleting favorites list:", error);
      return rejectWithValue(
        error.response?.data || "Failed to delete favorites list."
      );
    }
  }
);

export const addPropertyToListThunk = createAsyncThunk(
  "favorites/addProperty",
  async ({ listId, property }, { getState, rejectWithValue, dispatch }) => {
    try {
      console.log("Trying to add property to list", property);
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.post(
        `/v1/api/favorites/${listId}/properties`,
        { property }, // Send property in body
        config
      );

      // Extract the added property from the response
      const addedProperty = response.data.list.properties.find(
        (prop) => prop.mlsNumber === property.mlsNumber
      );

      return { listId, property: addedProperty };
    } catch (error) {
      console.error("Error adding property to list:", error);
      return rejectWithValue(
        error.response?.data || "Failed to add property to list."
      );
    }
  }
);

export const removePropertyFromListThunk = createAsyncThunk(
  "favorites/removeProperty",
  async ({ listId, propertyId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      await api.delete(
        `/v1/api/favorites/${listId}/properties/${propertyId}`,
        config
      );

      return { listId, propertyId }; // Return IDs to update state
    } catch (error) {
      console.error("Error removing property from list:", error);
      return rejectWithValue(
        error.response?.data || "Failed to remove property from list."
      );
    }
  }
);
